import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/content-full-width'
import { Helmet } from 'react-helmet'
import { column } from 'styles/column'
import { useToggle } from 'hooks/use-toggle'
import formatPrice from 'utils/format-price'
import pmt from 'utils/pmt'
import QuoteForm from 'components/forms/quote'
import Modal from 'components/modal/modal'
import Button from 'components/button/button'
import Disclaimer from 'components/landing-page/disclaimer'
import Spacer from 'components/new/spacer'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { H2, H3, P } from '~/components/new/typography'
import PromotionBanner from '~/components/new/promotion-banner'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Ul from '~/components/new/ul'

const dataTable = [
  {
    section: 1,
    id: '1023e',
    imageId: 'image1023E',
    sku: '1023elv',
    title: '2024 John Deere 1023E',
    subtitle: 'with 120R loader',
    modelName: '1023E',
    listPrice: 22024,
    price: 17900,
    financing: {
      rate: 0,
      term: 84,
      downPayment: 0.1,
    },
    labels: [
      `${(22024 - 17900).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })} off list`,
      `Low monthly payment`,
    ],
    // slug: '/lp/1023e/',
  },
  {
    section: 2,
    id: '1025r',
    imageId: 'image1025R',
    sku: '1025rlv',
    title: '2024 John Deere 1025R',
    subtitle: 'with 120R loader & 60-in AutoConnect Deck',
    modelName: '1025R',
    listPrice: 29073,
    price: 24000,
    financing: {
      rate: 0,
      term: 84,
    },
    labels: [
      `${(29073 - 24000).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })} off list`,
    ],
    // slug: '/lp/1025r/',
  },
  // {
  //   id: '1025r-tlb',
  //   imageId: 'image1025RTLB',
  //   sku: '1025rlv',
  //   title: '2024 John Deere 1025R',
  //   subtitle: 'with 120R loader & backhoe',
  //   modelName: '1025R',
  //   listPrice: 33750,
  //   price: 28499,
  //   financing: {
  //     rate: 0,
  //     term: 84,
  //   },
  //   labels: [
  //     `${(33750 - 28499).toLocaleString('en-US', {
  //       style: 'currency',
  //       currency: 'USD',
  //       minimumFractionDigits: 0,
  //     })} off list`,
  //   ],
  //   slug: '/lp/1025r-tlb/',
  // },
  {
    section: 2,
    id: '3025e',
    imageId: 'image3025E',
    sku: '3025elv',
    title: '2024 John Deere 3025E',
    subtitle: 'with 300E loader',
    modelName: '3025E',
    listPrice: 29996,
    price: 25900,
    financing: {
      rate: 0,
      term: 84,
    },
    labels: [
      `${(29996 - 25900).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })} off list`,
    ],
    // slug: '/lp/3025e/',
  },
  {
    section: 3,
    id: 'maintainer-package',
    imageId: 'maintainerImage',
    sku: '1025rlv',
    title: 'Maintainer Package',
    subtitle: '2024 John Deere 1025R',
    includes: [
      '1025R 25 HP tractor',
      '120R loader',
      '60-in AutoConnect deck',
      '48-in Frontier box blade',
    ],
    modelName: '1025R Maintainer Package',
    listPrice: null,
    price: 26099,
    financing: {
      rate: 0,
      term: 84,
    },
    labels: null,
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/maintainer/',
  },
  {
    section: 3,
    id: 'groundbreaker-package',
    imageId: 'groundbreakerImage',
    sku: '1025rlv',
    title: 'Groundbreaker Package',
    subtitle: '2024 John Deere 1025R',
    includes: [
      '1025R 25 HP tractor',
      '120R loader',
      '260B backhoe',
      '36-in Frontier pallet forks',
      '49-in Frontier rotary tiller',
    ],
    modelName: '1025R Groundbreaker Package',
    listPrice: null,
    price: 32895,
    financing: {
      rate: 0,
      term: 84,
    },
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/groundbreaker/',
  },
  {
    section: 3,
    id: 'gardener-package',
    imageId: 'gardenerImage',
    sku: '3025elv',
    title: 'Gardener Package',
    subtitle: '2024 John Deere 3025E',
    includes: [
      '3025E 25 HP tractor',
      '300E loader',
      '42-in Frontier pallet forks',
      '62-in Frontier rotary tiller',
    ],
    modelName: '3025E Gardener Package',
    listPrice: null,
    price: 30345,
    financing: {
      rate: 0,
      term: 84,
    },
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/gardener/',
  },
  // {
  //   section: 3,
  //   id: 'farm-hand-package',
  //   imageId: 'farmHandImage',
  //   sku: '3032elv',
  //   title: 'Farm Hand Package',
  //   subtitle: '2024 John Deere 3032E',
  //   includes: [
  //     '3032E 32 HP tractor',
  //     '300E loader',
  //     '54-in Frontier mechanical grapple',
  //     '60-in Frontier rotary cutter',
  //     '60-in Frontier box blade',
  //   ],
  //   modelName: '3032E Farm Hand Package',
  //   listPrice: null,
  //   price: 32299,
  //   financing: {
  //     rate: 0,
  //     term: 84,
  //   },
  //   slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/farm-hand/',
  // },
  // {
  //   section: 3,
  //   id: 'turf-tamer-package',
  //   imageId: 'turfTamerImage',
  //   sku: '2032rlv',
  //   title: 'Turf Tamer Package',
  //   subtitle: '2024 John Deere 2032R',
  //   includes: [
  //     '2032R 32 HP tractor',
  //     '220R loader',
  //     '60-in AutoConnect deck',
  //     '60-in Frontier core aerator',
  //     'Frontier 3-point broadcast spreader',
  //   ],
  //   modelName: '2032R Turf Tamer Package',
  //   listPrice: null,
  //   price: 38999,
  //   financing: {
  //     rate: 0,
  //     term: 84,
  //   },
  //   slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/turf-tamer/',
  // },
  {
    section: 3,
    id: 'snow-prepper-package',
    imageId: 'snowPrepperImage',
    sku: '1025rlv',
    title: 'Snow Prepper Package',
    subtitle: '2024 John Deere 1025R',
    includes: [
      '1025R 25 HP tractor',
      'Enclosed cab',
      '120R loader',
      '60-in Frontier snow push',
      '60-in Frontier rear blade',
    ],
    modelName: '1025R Snow Prepper Package',
    listPrice: null,
    price: 31225,
    financing: {
      rate: 0,
      term: 84,
    },
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/snow-prepper/',
  },
]

const CompactTractorsLP = props => {
  const {
    data: { allDeereProduct, heroImage, priorYearModelSaleImage },
    data,
    location,
  } = props

  const products = dataTable.map(prod => {
    const match = allDeereProduct.nodes.find(node => node.sku === prod.sku) || {}
    return {
      ...match,
      ...prod,
      image: data[prod.imageId],
    }
  })

  const heroProduct = products.find(prod => prod.section === 1)
  const secondaryProducts = products.filter(prod => prod.section === 2)
  const tertiaryProducts = products.filter(prod => prod.section === 3)

  const [open, toggleModal] = useToggle(false)
  const [selectedProduct, setSelectedProduct] = useState()

  useEffect(() => {
    if (window && window.dataLayer && selectedProduct) {
      window.dataLayer.push({
        'product-price': selectedProduct.price || 0,
      })
    }
  }, [selectedProduct])

  const openQuoteForm = e => {
    const target = e.target
    const id = target.getAttribute('data-id')
    const match = products.find(obj => obj.id === id)
    if (match) {
      setSelectedProduct(match)
      toggleModal(true)
    }
  }
  return (
    <Layout>
      <Helmet>
        <title>John Deere Compact Tractor Deals | Hutson Inc</title>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Hero>
        <HeroImageContainer>
          <HeroText>
            <Title>John Deere Compact Tractor Deals</Title>
            <Description>
              Browse our latest offers on new &lt;100 hp compact utility tractors
            </Description>
          </HeroText>
          <HeroOverlay />
          <HeroImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt=''
            objectFit='cover'
            objectPosition='75% 50%'
          />
        </HeroImageContainer>
      </Hero>
      <LightBackground>
        <Content>
          <PromotionBanner
            text='0% for 84 months financing available on all <100 HP John Deere compact utility tractors'
            outboundLink='https://creditapp.financial.deere.com/?dealer=034321&type=CUTT&country=us'
            linkText='Apply online'
          />
          <Spacer size='l' />
          <H2>Our lowest priced tractor</H2>
          <Spacer size='m' />
          <HeroOfferContainer>
            <HeroOfferImage>
              {Array.isArray(heroProduct.labels) ? (
                <HeroOfferLabels>
                  {heroProduct.labels.map(label => (
                    <span>{label}</span>
                  ))}
                </HeroOfferLabels>
              ) : null}
              <GatsbyImage
                image={heroProduct.image.childImageSharp.gatsbyImageData}
                alt={heroProduct.title}
              />
            </HeroOfferImage>
            <HeroOfferContent>
              <GridItemTextContainer>
                <GridItemTitle>{heroProduct.title}</GridItemTitle>
                {heroProduct.subtitle ? <GridItemText>{heroProduct.subtitle}</GridItemText> : null}
                <GridItemPricingContainer>
                  {heroProduct.price ? (
                    <GridItemPrice>
                      {heroProduct.price.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                      })}
                    </GridItemPrice>
                  ) : (
                    <PricingMessage>Contact us for a quote</PricingMessage>
                  )}
                  {heroProduct.listPrice ? (
                    <GridItemWasPrice>
                      {heroProduct.listPrice.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                      })}{' '}
                      List
                    </GridItemWasPrice>
                  ) : null}
                  {heroProduct.price || heroProduct.listPrice ? <br /> : null}
                  {heroProduct.price ? (
                    <>
                      <span>or</span>{' '}
                      <GridItemPrice>
                        {formatPrice(
                          pmt(
                            heroProduct.financing.rate,
                            heroProduct.financing.term,
                            heroProduct.price *
                              (1 -
                                (heroProduct.financing.downPayment
                                  ? heroProduct.financing.downPayment
                                  : 0))
                          ),
                          {
                            round: true,
                          }
                        )}
                      </GridItemPrice>
                      <span>/month</span> <br />
                      at{' '}
                    </>
                  ) : null}
                  {heroProduct.financing.rate === 0
                    ? 0
                    : (heroProduct.financing.rate * 100).toFixed(2)}
                  % for {heroProduct.financing.term} months with{' '}
                  {heroProduct.financing.downPayment
                    ? `${(heroProduct.financing.downPayment * 100).toFixed(0)}%`
                    : `no money`}{' '}
                  down
                </GridItemPricingContainer>
                <Spacer size='xxs' />
                <div>
                  <GridItemButton data-id={heroProduct.id} onClick={openQuoteForm}>
                    {heroProduct.price ? 'Talk to sales' : 'Get a quote'}
                  </GridItemButton>
                  <Button
                    color='green'
                    ghost
                    as={SecondaryButtonA}
                    href={`https://creditapp.financial.deere.com/?dealer=034321&ref=${heroProduct.sku}&type=CUTT&country=us`}
                  >
                    Apply for financing
                  </Button>
                </div>
                {heroProduct.slug ? (
                  <div>
                    <Spacer size='xs' />
                    <GridItemLearnMoreLink to={heroProduct.slug}>
                      <span>Learn more about the {heroProduct.modelName || heroProduct.title}</span>
                    </GridItemLearnMoreLink>
                  </div>
                ) : null}
              </GridItemTextContainer>
            </HeroOfferContent>
          </HeroOfferContainer>
          <Spacer />
          <H2>Deals on other popular models</H2>
          <P>Our best-selling models at our best prices.</P>
          <Spacer size='m' />
          <Grid>
            {secondaryProducts.map(prod => (
              <GridItem key={prod.id}>
                {Array.isArray(prod.labels) ? (
                  <GridItemOfferLabels>
                    {prod.labels.map(label => (
                      <span>{label}</span>
                    ))}
                  </GridItemOfferLabels>
                ) : null}
                {prod.image?.childImageSharp ? (
                  <GridItemImage
                    image={prod.image.childImageSharp.gatsbyImageData}
                    alt={prod.title}
                  />
                ) : null}
                <GridItemTextContainer>
                  <GridItemTitleContainer>
                    <GridItemTitle>{prod.title}</GridItemTitle>
                    {prod.subtitle ? <GridItemText>{prod.subtitle}</GridItemText> : null}
                  </GridItemTitleContainer>
                  {prod.description ? (
                    <React.Fragment>
                      <Spacer size='s' />
                      <GridItemText>{prod.description}</GridItemText>
                    </React.Fragment>
                  ) : null}
                  <GridItemPricingContainer>
                    {prod.price ? (
                      <GridItemPrice>
                        {prod.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                        })}
                      </GridItemPrice>
                    ) : (
                      <PricingMessage>Contact us for a quote</PricingMessage>
                    )}
                    {prod.listPrice ? (
                      <GridItemWasPrice>
                        {prod.listPrice.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                        })}{' '}
                        List
                      </GridItemWasPrice>
                    ) : null}
                    {prod.price || prod.listPrice ? <br /> : null}
                    {prod.price ? (
                      <>
                        <span>or</span>{' '}
                        <GridItemPrice>
                          {formatPrice(
                            pmt(
                              prod.financing.rate,
                              prod.financing.term,
                              prod.price *
                                (1 - (prod.financing.downPayment ? prod.financing.downPayment : 0))
                            ),
                            {
                              round: true,
                            }
                          )}
                        </GridItemPrice>
                        <span>/month</span> <br />
                        at{' '}
                      </>
                    ) : null}
                    {prod.financing.rate === 0 ? 0 : (prod.financing.rate * 100).toFixed(2)}% for{' '}
                    {prod.financing.term} months with{' '}
                    {prod.financing.downPayment
                      ? `${(prod.financing.downPayment * 100).toFixed(0)}%`
                      : `no money`}{' '}
                    down
                  </GridItemPricingContainer>
                  <Spacer size='xxs' />
                  <div>
                    <GridItemButton data-id={prod.id} onClick={openQuoteForm}>
                      {prod.price ? 'Talk to sales' : 'Get a quote'}
                    </GridItemButton>
                    {prod.price ? (
                      <Button
                        color='green'
                        ghost
                        as={SecondaryButtonA}
                        href={`https://creditapp.financial.deere.com/?dealer=034321&ref=${prod.sku}&type=CUTT&country=us`}
                      >
                        Apply for financing
                      </Button>
                    ) : null}
                  </div>
                  {prod.slug ? (
                    <div>
                      <Spacer size='xs' />
                      <GridItemLearnMoreLink to={prod.slug}>
                        <span>Learn more about the {prod.modelName || prod.title}</span>
                      </GridItemLearnMoreLink>
                    </div>
                  ) : null}
                </GridItemTextContainer>
              </GridItem>
            ))}
          </Grid>
        </Content>
        <FlexGrid>
          <div>
            <GatsbyImage
              image={getImage(priorYearModelSaleImage)}
              objectFit='cover'
              objectPosition='50% 50%'
              style={{ height: '100%', width: '100%' }}
              alt=''
            />
          </div>
          <FlexGridTextColumn>
            <H3 as='h2'>End of Season Sales Event</H3>
            <P>
              Special clearance pricing on select John Deere compact utility tractors. Available
              while supplies last.
            </P>
            <Button as={Link} to='/lp/end-of-season-sales-event/'>
              Browse our inventory
            </Button>
          </FlexGridTextColumn>
        </FlexGrid>
        <Content kind='full'>
          <H2>Get the complete package</H2>
          <P>Browse our pre-configured tractor packages built so you can get right to work.</P>
          <Spacer size='m' />
          <Grid>
            {tertiaryProducts.map(prod => (
              <GridItemThreeCol key={prod.id}>
                {Array.isArray(prod.labels) ? (
                  <GridItemOfferLabels>
                    {prod.labels.map(label => (
                      <span>{label}</span>
                    ))}
                  </GridItemOfferLabels>
                ) : null}
                {prod.image?.childImageSharp ? (
                  <GridItemImage
                    image={prod.image.childImageSharp.gatsbyImageData}
                    alt={prod.title}
                  />
                ) : null}
                <GridItemTextContainer>
                  <GridItemTitleContainer>
                    <GridItemTitle>{prod.title}</GridItemTitle>
                    {prod.subtitle ? <GridItemText>{prod.subtitle}</GridItemText> : null}
                  </GridItemTitleContainer>
                  {prod.includes ? (
                    <React.Fragment>
                      <Spacer size='s' />
                      <GridItemIncludes>
                        <P>Package Includes:</P>
                        <Ul>
                          {prod.includes.map(lineItem => (
                            <li key={lineItem}>{lineItem}</li>
                          ))}
                        </Ul>
                      </GridItemIncludes>
                    </React.Fragment>
                  ) : null}
                  <GridItemPricingContainer>
                    {prod.price ? (
                      <GridItemPrice>
                        {prod.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                        })}
                      </GridItemPrice>
                    ) : (
                      <PricingMessage>Contact us for a quote</PricingMessage>
                    )}
                    {prod.listPrice ? (
                      <GridItemWasPrice>
                        {prod.listPrice.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                        })}{' '}
                        List
                      </GridItemWasPrice>
                    ) : null}
                    {prod.price || prod.listPrice ? <br /> : null}
                    {prod.price ? (
                      <>
                        <span>or</span>{' '}
                        <GridItemPrice>
                          {formatPrice(
                            pmt(
                              prod.financing.rate,
                              prod.financing.term,
                              prod.price *
                                (1 - (prod.financing.downPayment ? prod.financing.downPayment : 0))
                            ),
                            {
                              round: true,
                            }
                          )}
                        </GridItemPrice>
                        <span>/month</span> <br />
                        at{' '}
                      </>
                    ) : null}
                    {prod.financing.rate === 0 ? 0 : (prod.financing.rate * 100).toFixed(2)}% for{' '}
                    {prod.financing.term} months with{' '}
                    {prod.financing.downPayment
                      ? `${(prod.financing.downPayment * 100).toFixed(0)}%`
                      : `no money`}{' '}
                    down
                  </GridItemPricingContainer>
                  <Spacer size='xxs' />
                  <div>
                    <GridItemButton data-id={prod.id} onClick={openQuoteForm}>
                      {prod.price ? 'Talk to sales' : 'Get a quote'}
                    </GridItemButton>
                    {prod.price ? (
                      <Button
                        color='green'
                        ghost
                        as={SecondaryButtonA}
                        href={`https://creditapp.financial.deere.com/?dealer=034321&ref=${prod.id}&type=CUTT&country=us`}
                      >
                        Apply for financing
                      </Button>
                    ) : null}
                  </div>
                  {prod.slug ? (
                    <div>
                      <Spacer size='xs' />
                      <GridItemLearnMoreLink to={prod.slug}>
                        <span>Learn more about the {prod.modelName || prod.title}</span>
                      </GridItemLearnMoreLink>
                    </div>
                  ) : null}
                </GridItemTextContainer>
              </GridItemThreeCol>
            ))}
          </Grid>
          <Spacer />
          <Disclaimer>
            Offers valid October 2 through October 25, 2024 while supplies last. Prices and
            availability may vary by dealer. Taxes not included. Financing subject to approved
            credit by John Deere Financial. Down payment may be required to receive financing.
            Images for illustration purposes only. Residency restrictions, terms and conditions may
            apply See dealer for details.
          </Disclaimer>
        </Content>
      </LightBackground>

      <Modal toggle={toggleModal} isOpen={open}>
        <QuoteForm
          toggleModal={toggleModal}
          productName={`Compact Tractors 2024 Q4${
            selectedProduct ? ` - ${selectedProduct.title}` : ''
          }`}
          productPrice={selectedProduct ? selectedProduct.price || 0 : 0}
          productType='john-deere'
          submitButtonClass='submit-quote'
          pageURL={location.href}
          hiddenValues={[
            ...(selectedProduct?.sanityData?.categoryCode
              ? [
                  {
                    name: 'Category Code',
                    value: selectedProduct.sanityData.categoryCode,
                  },
                ]
              : []),
            ...(selectedProduct?.sanityData?.subcategoryCode
              ? [
                  {
                    name: 'Subcategory Code',
                    value: selectedProduct.sanityData.subcategoryCode,
                  },
                ]
              : []),
          ]}
          header={selectedProduct && selectedProduct.price ? 'Talk to sales' : 'Get a quote'}
          equipmentBrand='John Deere'
          equipmentCondition='new'
        />
      </Modal>
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n10};
`

const Hero = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    height: 500px;
  }
`

const HeroImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const HeroOverlay = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (min-width: 600px) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1) 100%);
  }
`

const HeroText = styled(Content)`
  color: #fff;
  left: 50%;
  padding-bottom: 0;
  position: absolute;
  bottom: 10%;
  transform: translateX(-50%);
  z-index: 2;
`

const HeroImage = styled(GatsbyImage)`
  min-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`

const Title = styled.h1`
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.25;
  margin: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 48px;
  }
`

const Description = styled.p`
  color: #efefef;
  font-size: 21px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 24px;
  }
`

const HeroOfferContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    align-content: flex-end;
    flex-direction: row;
  }
`

const HeroOfferImage = styled.div`
  margin-bottom: 16px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    margin-bottom: 0;
    width: 50%;
  }
`

const HeroOfferContent = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    width: 50%;
  }
`

const HeroOfferLabels = styled.div`
  margin-bottom: 16px;
  z-index: 1;

  span {
    background-color: #fee2e2;
    color: #ef4444;
    font-size: 13px;
    font-weight: 700;
    margin: 0 4px 4px 0;
    padding: 4px 6px;
  }

  @media (min-width: 800px) {
    left: 0;
    position: absolute;
    top: 0;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GridItem = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 24px;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    ${column('1/2')}
    float: none;
    clear: none;
  }
`

const GridItemThreeCol = styled(GridItem)`
  @media (min-width: 1000px) {
    ${column('1/3')}
  }
`

// const FinancingGridItem = styled(GridItem)`
//   background-color: #ffde00;

//   hr {
//     background-color: transparent;
//     border: none;
//     border-top: 1px dashed #000;
//     height: 1px;
//     margin: 8px 0;
//     width: 100%;
//   }
// `

// const FinancingTitle = styled.h2`
//   font-size: 1.875em;
//   margin: 0 0 4px;
// `

// const FinancingDetails = styled.p`
//   font-size: 1.125em;
//   margin: 0;
// `

const GridItemImage = styled(GatsbyImage)`
  width: 100%;
`

const GridItemOfferLabels = styled.div`
  margin-bottom: 16px;

  span {
    background-color: #fee2e2;
    color: #ef4444;
    font-size: 13px;
    font-weight: 700;
    margin: 0 4px 4px 0;
    padding: 4px 6px;
  }
`

const GridItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  margin-top: 24px;
`

const GridItemLearnMoreLink = styled(Link)`
  color: #377539;
  display: inline-block;
  text-decoration: none;

  span {
    text-decoration: underline;
  }

  :hover,
  :focus {
    color: #000;
  }

  :after {
    content: ' ›';
    display: inline;
    text-decoration: none;
  }
`

const GridItemTitleContainer = styled.div`
  flex-grow: 1;
`

const GridItemTitle = styled.h3`
  display: inline-block;
  font-size: 27px;
  font-weight: 600;
  margin: 0 8px 0 0;
  max-width: none;
  padding: 0;
  text-transform: none;
  width: 100%;
`

const GridItemText = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  margin-right: 5px;
`

const GridItemIncludes = styled.div`
  font-size: 14px;

  p {
    font-weight: 700;
    line-height: 1;
    margin: 0;
  }

  li {
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }
`

const GridItemPricingContainer = styled.div`
  flex-grow: 2;
  line-height: 1.35;
  margin-top: 8px;
`

const PricingMessage = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
`

const GridItemPrice = styled.span`
  display: inline-block;
  font-size: 1.75em;
  font-weight: 700;
  margin-right: ${props => (props.nomargin ? 0 : '5px')};
`

const GridItemWasPrice = styled.span`
  color: #7d7d7d;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 400;
`

const GridItemButton = styled(Button)`
  display: inline-block;
  margin: 20px 8px 8px 0;
`

const SecondaryButtonA = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 0 16px;
  text-decoration: none;
`

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row-reverse;
    min-height: 400px;

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  background-color: ${props => props.theme.color.n700};
  padding: 36px 24px;

  h2,
  p {
    color: #fff;
  }

  a {
    display: inline-block;
    margin-top: 16px;
    text-decoration: none;
  }

  @media (min-width: 900px) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

export const pageQuery = graphql`
  {
    allDeereProduct(
      filter: { sku: { in: ["1023elv", "1025rlv", "2032rlv", "3025elv", "3032elv"] } }
    ) {
      nodes {
        sanityData {
          categoryCode
          subcategoryCode
        }
        slug
        sku
      }
    }
    heroImage: file(relativePath: { eq: "landing-pages/get-in-the-seat-hero.jpg" }) {
      ...FullWidthImage
    }
    priorYearModelSaleImage: file(
      relativePath: { eq: "homepage/cuts-prior-year-model-sale-slide.jpg" }
    ) {
      ...SharpImage800
    }
    image1023E: file(relativePath: { eq: "landing-pages/assets/1023e-loader.jpg" }) {
      ...SharpImage800
    }
    image1025R: file(relativePath: { eq: "landing-pages/assets/1025r-loader.jpg" }) {
      ...SharpImage800
    }
    image1025RTLB: file(relativePath: { eq: "landing-pages/assets/1025r-tlb.jpg" }) {
      ...SharpImage800
    }
    image3025E: file(relativePath: { eq: "landing-pages/assets/3025e-loader.jpg" }) {
      ...SharpImage800
    }
    maintainerImage: file(relativePath: { eq: "tractor-packages/maintainer-tractor-package.jpg" }) {
      ...SharpImage900
    }
    groundbreakerImage: file(
      relativePath: { eq: "tractor-packages/groundbreaker-tractor-package.jpg" }
    ) {
      ...SharpImage900
    }
    gardenerImage: file(relativePath: { eq: "tractor-packages/gardener-tractor-package.jpg" }) {
      ...SharpImage900
    }
    farmHandImage: file(relativePath: { eq: "tractor-packages/farm-hand-tractor-package.jpg" }) {
      ...SharpImage900
    }
    turfTamerImage: file(
      relativePath: { eq: "tractor-packages/turf-tamer-tractor-package-v2.jpg" }
    ) {
      ...SharpImage900
    }
    snowPrepperImage: file(
      relativePath: { eq: "tractor-packages/snow-prepper-tractor-package.jpg" }
    ) {
      ...SharpImage900
    }
  }
`

export default CompactTractorsLP
